<template>
	<w-btn v-if="!model" flat icon="info" mini @click="model = true">
		{{ $t('ecm.show-selection-details') }}
	</w-btn>
</template>

<script>
export default {
	name: 'ShowSelectionDetails',
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>
